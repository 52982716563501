import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { TrustPilotProps } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { mq, spacing, styled } from '@thg-commerce/enterprise-theme'
import { TrustBox } from '@thg-commerce/enterprise-widget-trustpilot'

import { FooterWidget as FOOTER_WIDGET_QUERY } from '../../widgets/graphql/Query/FooterWidget.graphql'

import { FooterWidgets } from './types'

const AccreditationsWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  width: 100%;
  display: flex;
  justify-content: center;
`

const AccreditationsContainer = styled.div`
  background-color: ${(props) => props.theme.surface.style.backgroundColor};
  max-width: 1440px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: ${spacing(1)} ${spacing(3)} ${spacing(4)} ${spacing(3)};
  width: 100%;
  height: auto;
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    height: 228px;
    padding: ${spacing(1)} ${spacing(1)} ${spacing(4)} ${spacing(1)};
  }
`

const AccreditationLinkWrapper = styled.div`
  flex: 0 0 calc(50% - ${spacing(4)});
  margin: ${spacing(2)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex: 0 0 calc(25% - ${spacing(4)});
  }
`

const AccreditationImg = styled.img`
  max-width: 100%;
  margin: auto;
`

const ImageLink = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  :focus {
    outline-color: ${(props) => props.theme.colors.palette.brand.base};
  }
`
const AccreditationImageContainer = styled.div`
  height: auto;
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    height: 140px;
  }
`

const StyledTrustBox = styled(TrustBox)`
  display: flex;
  justify-content: center;
  padding-top: ${spacing(2)};
  max-width: 300px;
  margin: 0 auto;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding-top: 0;
  }
`
const StyledSubtitle = styled.div`
  display: block;
  border-collapse: collapse;
  box-sizing: border-box;
  text-align: center;
  width: auto;
  height: 100%;
  align-items: center;
  margin: auto 0;
`

export const FooterGlobalAccredditationsIcons = () => {
  const { data } = useQuery(FOOTER_WIDGET_QUERY)

  return <AccreditationBanner widgetQueryResult={data} />
}

interface AccreditationBannerProps {
  widgetQueryResult: FooterWidgets
}

const TrustBoxAccreditation = (trustPilotProps: TrustPilotProps) => {
  return (
    <AccreditationLinkWrapper data-testid="accreditation-link-wrapper-0">
      <StyledTrustBox
        templateId={trustPilotProps.templateId}
        link={trustPilotProps.link}
        height={trustPilotProps.height}
        width={`${trustPilotProps.width}%`}
        trustBoxTheme={trustPilotProps.theme}
        locale={trustPilotProps.locale}
        businessId={trustPilotProps.businessId}
      />
    </AccreditationLinkWrapper>
  )
}

const AccreditationBanner = ({
  widgetQueryResult,
}: AccreditationBannerProps) => {
  const { trustPilot } = useSiteConfig()
  const icons = widgetQueryResult?.footer?.widgets[0]

  if (!icons && !trustPilot?.enableTrustBox) {
    return null
  }

  return (
    <AccreditationsWrapper>
      <AccreditationsContainer>
        {trustPilot?.enableTrustBox && (
          <TrustBoxAccreditation {...trustPilot} />
        )}
        {icons?.image1 && (
          <AccreditationLinkWrapper data-testid="accreditation-link-wrapper-1">
            <AccreditationImageContainer>
              <ImageLink href={icons.image1Href || ''}>
                <AccreditationImg
                  src={icons.image1}
                  alt={icons.image1Alt || 'AccreditationLogo1'}
                />
              </ImageLink>
            </AccreditationImageContainer>
            {icons?.subtitle1 && (
              <StyledSubtitle>{icons.subtitle1}</StyledSubtitle>
            )}
          </AccreditationLinkWrapper>
        )}
        {icons?.image2 && (
          <AccreditationLinkWrapper data-testid="accreditation-link-wrapper-2">
            <AccreditationImageContainer>
              <ImageLink href={icons.image2Href || ''}>
                <AccreditationImg
                  src={icons.image2}
                  alt={icons.Image2Alt || 'AccreditationLogo2'}
                />
              </ImageLink>
            </AccreditationImageContainer>
            {icons?.subtitle2 && (
              <StyledSubtitle>{icons.subtitle2}</StyledSubtitle>
            )}
          </AccreditationLinkWrapper>
        )}
        {icons?.image3 && (
          <AccreditationLinkWrapper data-testid="accreditation-link-wrapper-3">
            <AccreditationImageContainer>
              <ImageLink href={icons.Image3Href || ''}>
                <AccreditationImg
                  src={icons.image3}
                  alt={icons.Image3Alt || 'AccreditationLogo3'}
                />
              </ImageLink>
            </AccreditationImageContainer>
            {icons?.subtitle3 && (
              <StyledSubtitle>{icons.subtitle3}</StyledSubtitle>
            )}
          </AccreditationLinkWrapper>
        )}
      </AccreditationsContainer>
    </AccreditationsWrapper>
  )
}
